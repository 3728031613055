//
// Pages: Invoice
//

.invoice {
    background: $white;
    border: 1px solid $card-border-color;
    position: relative;
}

.invoice-title {
    margin-top: 0;
}