//
// Component: Alert
//

.alert {
    .icon {
        margin-right: 10px;
    }

    .close {
        color: $black;
        opacity: .2;

        &:hover {
            opacity: .5;
        }
    }

    a {
        color: $white;
        text-decoration: underline;
    }
}

//Alert Variants
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        color: color-yiq($value);
        background: $value;
        border-color: darken($value, 5%);
    }

    .alert-default-#{$color} {
        @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
}