//
// Mixins: Cards Variant
//

@mixin cards-variant($name, $color) {
    .card-#{$name} {
        &:not(.card-outline) {
            > .card-header {
                background-color: $color;

                &,
                a {
                    color: color-yiq($color);
                }

                a.active {
                    color: color-yiq($white);
                }
            }
        }

        &.card-outline {
            border-top: 3px solid $color;
        }

        &.card-outline-tabs {
            > .card-header {
                a {
                    &:hover {
                        border-top: 3px solid $nav-tabs-border-color;
                    }

                    &.active {
                        border-top: 3px solid $color;
                    }
                }
            }
        }
    }

    .bg-#{$name},
    .bg-gradient-#{$name},
    .card-#{$name}:not(.card-outline) {
        .btn-tool {
            color: rgba(color-yiq($color), 0.8);

            &:hover {
                color: color-yiq($color);
            }
        }
    }

    .card.bg-#{$name},
    .card.bg-gradient-#{$name} {
        .bootstrap-datetimepicker-widget {
            .table td,
            .table th {
                border: none;
            }

            table thead tr:first-child th:hover,
            table td.day:hover,
            table td.hour:hover,
            table td.minute:hover,
            table td.second:hover {
                background: darken($color, 8%);
                color: color-yiq($color);
            }

            table td.today::before {
                border-bottom-color: color-yiq($color);
            }

            table td.active,
            table td.active:hover {
                background: lighten($color, 10%);
                color: color-yiq($color);
            }
        }
    }
}