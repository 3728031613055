//
// Mixins: Accent
//

// Accent Variant
@mixin accent-variant($name, $color) {
    .accent-#{$name} {
        $link-color: $color;
        $link-hover-color: darken($color, 15%);
        $pagination-active-bg: $color;
        $pagination-active-border-color: $color;

        .btn-link,
        a:not(.dropdown-item):not(.btn-app):not(.nav-link):not(.brand-link):not(.page-link):not(.btn) {
            color: $link-color;

            @include hover {
                color: $link-hover-color;
            }
        }

        .dropdown-item {
            &:active,
            &.active {
                background: $color;
                color: color-yiq($color);
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::before {
                background: $color;
                border-color: darken($color, 20%);
            }

            &::after {
                $newColor: color-yiq($color);
                background-image: str-replace($custom-checkbox-indicator-icon-checked, str-replace(#{$custom-control-indicator-checked-color}, '#', '%23'), str-replace(#{$newColor}, '#', '%23'));
            }
        }

        .form-control:focus:not(.is-invalid):not(.is-warning):not(.is-valid),
        .custom-select:focus,
        .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
        .custom-file-input:focus ~ .custom-file-label {
            border-color: lighten($color, 25%);
        }

        .page-item {
            .page-link {
                color: $link-color;
            }

            &.active a,
            &.active .page-link {
                background-color: $pagination-active-bg;
                border-color: $pagination-active-border-color;
                color: $pagination-active-color;
            }

            &.disabled a,
            &.disabled .page-link {
                background-color: $pagination-disabled-bg;
                border-color: $pagination-disabled-border-color;
                color: $pagination-disabled-color;
            }
        }

        [class*="sidebar-dark-"] {
            .sidebar {
                a:not(.dropdown-item):not(.btn-app):not(.nav-link):not(.brand-link) {
                    color: $sidebar-dark-color;

                    @include hover {
                        color: $sidebar-dark-hover-color;
                    }
                }
            }
        }

        [class*="sidebar-light-"] {
            .sidebar {
                a:not(.dropdown-item):not(.btn-app):not(.nav-link):not(.brand-link) {
                    color: $sidebar-light-color;

                    @include hover {
                        color: $sidebar-light-hover-color;
                    }
                }
            }
        }
    }
}