//
// Component: Main Header
//

.main-header {
    border-bottom: $main-header-bottom-border;
    z-index: $zindex-main-header;

    .nav-link {
        height: $nav-link-height;
        position: relative;
    }

    .text-sm &,
    &.text-sm {
        .nav-link {
            height: $nav-link-sm-height;
            padding: $nav-link-sm-padding-y $nav-link-padding-x;

            > .fa,
            > .fas,
            > .far,
            > .fab,
            > .glyphicon,
            > .ion {
                font-size: $font-size-sm;
            }
        }
    }

    .navbar-nav {
        .nav-item {
            margin: 0;
        }

        &[class*='-right'] {
            .dropdown-menu {
                left: auto;
                margin-top: -3px;
                right: 0;

                @media (max-width: breakpoint-max(xs)) {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}

// Add this class to images within a nav-link
.navbar-img {
    height: $main-header-height / 2;
    width: auto;
}

// Navbar badge
.navbar-badge {
    font-size: .6rem;
    font-weight: 300;
    padding: 2px 4px;
    position: absolute;
    right: 5px;
    top: 9px;
}

.btn-navbar {
    background-color: transparent;
    border-left-width: 0;
}

.form-control-navbar {
    border-right-width: 0;

    & + .input-group-append {
        margin-left: 0;
    }
}

.form-control-navbar,
.btn-navbar {
    transition: none;
}

.navbar-dark {
    .form-control-navbar,
    .btn-navbar {
        background-color: $main-header-dark-form-control-bg;
        border: $main-header-dark-form-control-border;
    }

    .form-control-navbar {
        &::placeholder {
            color: $main-header-dark-placeholder-color;
        }

        + .input-group-append > .btn-navbar {
            color: $main-header-dark-placeholder-color;
        }

        &:focus {
            &,
            & + .input-group-append .btn-navbar {
                background-color: $main-header-dark-form-control-focused-bg;
                border: $main-header-dark-form-control-focused-border !important;
                color: $main-header-dark-form-control-focused-color;
            }
        }
    }
}

.navbar-light {
    .form-control-navbar,
    .btn-navbar {
        background-color: $main-header-light-form-control-bg;
        border: $main-header-light-form-control-border;
    }

    .form-control-navbar {
        &::placeholder {
            color: $main-header-light-placeholder-color;
        }

        + .input-group-append > .btn-navbar {
            color: $main-header-light-placeholder-color;
        }

        &:focus {
            &,
            & + .input-group-append .btn-navbar {
                background-color: $main-header-light-form-control-focused-bg;
                border: $main-header-light-form-control-focused-border !important;
                color: $main-header-light-form-control-focused-color;
            }
        }
    }
}