//
// Plugin: Pace
//

.pace {
    z-index: $zindex-main-sidebar + 10;

    .pace-progress {
        z-index: $zindex-main-sidebar + 11;
    }

    .pace-activity {
        z-index: $zindex-main-sidebar + 12;
    }
}

// Mixin
@mixin pace-variant($name, $color) {
    .pace-#{$name} {
        .pace {
            .pace-progress {
                background: $color;
            }
        }
    }

    .pace-barber-shop-#{$name} {
        .pace {
            background: color-yiq($color);

            .pace-progress {
                background: $color;
            }

            .pace-activity {
                background-image: linear-gradient(45deg, rgba(color-yiq($color), 0.2) 25%, transparent 25%, transparent 50%, rgba(color-yiq($color), 0.2) 50%, rgba(color-yiq($color), 0.2) 75%, transparent 75%, transparent);
            }
        }
    }

    .pace-big-counter-#{$name} {
        .pace {
            .pace-progress::after {
                color: rgba($color, .19999999999999996);
            }
        }
    }

    .pace-bounce-#{$name} {
        .pace {
            .pace-activity {
                background: $color;
            }
        }
    }

    .pace-center-atom-#{$name} {
        .pace-progress {
            height: 100px;
            width: 80px;

            &::before {
                background: $color;
                color: color-yiq($color);
                font-size: .8rem;
                line-height: .7rem;
                padding-top: 17%;
            }
        }

        .pace-activity {
            border-color: $color;

            &::after,
            &::before {
                border-color: $color;
            }
        }
    }

    .pace-center-circle-#{$name} {
        .pace {
            .pace-progress {
                background: rgba($color, .8);
                color: color-yiq($color);
            }
        }
    }

    .pace-center-radar-#{$name} {
        .pace {
            .pace-activity {
                border-color: $color transparent transparent;
            }

            .pace-activity::before {
                border-color: $color transparent transparent;
            }
        }
    }

    .pace-center-simple-#{$name} {
        .pace {
            background: color-yiq($color);
            border-color: $color;

            .pace-progress {
                background: $color;
            }
        }
    }

    .pace-material-#{$name} {
        .pace {
            color: $color;
        }
    }

    .pace-corner-indicator-#{$name} {
        .pace {
            .pace-activity {
                background: $color;
            }

            .pace-activity::after,
            .pace-activity::before {
                border: 5px solid color-yiq($color);
            }

            .pace-activity::before {
                border-right-color: rgba($color, .2);
                border-left-color: rgba($color, .2);
            }

            .pace-activity::after {
                border-top-color: rgba($color, .2);
                border-bottom-color: rgba($color, .2);
            }
        }
    }

    .pace-fill-left-#{$name} {
        .pace {
            .pace-progress {
                background-color: rgba($color, 0.19999999999999996);
            }
        }
    }

    .pace-flash-#{$name} {
        .pace {
            .pace-progress {
                background: $color;
            }

            .pace-progress-inner {
                box-shadow: 0 0 10px $color, 0 0 5px $color;
            }

            .pace-activity {
                border-top-color: $color;
                border-left-color: $color;
            }
        }
    }

    .pace-loading-bar-#{$name} {
        .pace {
            .pace-progress {
                background: $color;
                color: $color;
                box-shadow: 120px 0 color-yiq($color), 240px 0 color-yiq($color);
            }

            .pace-activity {
                box-shadow: inset 0 0 0 2px $color, inset 0 0 0 7px color-yiq($color);
            }
        }
    }

    .pace-mac-osx-#{$name} {
        .pace {
            .pace-progress {
                background-color: $color;
                box-shadow: inset -1px 0 $color, inset 0 -1px $color, inset 0 2px rgba(color-yiq($color), 0.5), inset 0 6px rgba(color-yiq($color), .3);
            }

            .pace-activity {
                background-image: radial-gradient(rgba(color-yiq($color), .65) 0%, rgba(color-yiq($color), .15) 100%);
                height: 12px;
            }
        }
    }

    .pace-progress-color-#{$name} {
        .pace-progress {
            color: $color;
        }
    }
}

@each $name, $color in $theme-colors {
    @include pace-variant($name, $color);
}

@each $name, $color in $colors {
    @include pace-variant($name, $color);
}