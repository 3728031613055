//
// Component: Forms
//

.form-group {
    &.has-icon {
        position: relative;

        .form-control {
            padding-right: 35px;
        }

        .form-icon {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 1rem;
            // margin-top: -3px;
            padding: $input-btn-padding-y $input-btn-padding-x;
            position: absolute;
            right: 3px;
            top: 0;
        }
    }
}

// Button groups
.btn-group-vertical {
    .btn {
        &.btn-flat:first-of-type,
        &.btn-flat:last-of-type {
            @include border-radius(0);
        }
    }
}

// Support icons in form-control
.form-control-feedback {
    &.fa,
    &.fas,
    &.far,
    &.fab,
    &.glyphicon,
    &.ion {
        line-height: $input-height;
    }
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback {
    &.fa,
    &.fas,
    &.far,
    &.fab,
    &.glyphicon,
    &.ion {
        line-height: $input-height-lg;
    }
}

.form-group-lg {
    .form-control + .form-control-feedback {
        &.fa,
        &.fas,
        &.far,
        &.fab,
        &.glyphicon,
        &.ion {
            line-height: $input-height-lg;
        }
    }
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback {
    &.fa,
    &.fas,
    &.far,
    &.fab,
    &.glyphicon,
    &.ion {
        line-height: $input-height-sm;
    }
}

.form-group-sm {
    .form-control + .form-control-feedback {
        &.fa,
        &.fas,
        &.far,
        &.fab,
        &.glyphicon,
        &.ion {
            line-height: $input-height-sm;
        }
    }
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: $font-weight-bold;
}

.warning-feedback {
    @include font-size($form-feedback-font-size);
    color: theme-color('warning');
    display: none;
    margin-top: $form-feedback-margin-top;
    width: 100%;
}

.warning-tooltip {
    @include border-radius($form-feedback-tooltip-border-radius);
    @include font-size($form-feedback-tooltip-font-size);
    background-color: rgba(theme-color('warning'), $form-feedback-tooltip-opacity);
    color: color-yiq(theme-color('warning'));
    display: none;
    line-height: $form-feedback-tooltip-line-height;
    margin-top: .1rem;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    position: absolute;
    top: 100%;
    z-index: 5;
}

.form-control {
    &.is-warning {
        border-color: theme-color('warning');

        @if $enable-validation-icons {
            // padding-right: $input-height-inner;
            // background-image: none;
            // background-repeat: no-repeat;
            // background-position: center right $input-height-inner-quarter;
            // background-size: $input-height-inner-half $input-height-inner-half;
        }

        &:focus {
            border-color: theme-color('warning');
            box-shadow: 0 0 0 $input-focus-width rgba(theme-color('warning'), .25);
        }

        ~ .warning-feedback,
        ~ .warning-tooltip {
            display: block;
        }
    }
}

// stylelint-disable-next-line selector-no-qualifying-type
textarea.form-control {
    &.is-warning {
        @if $enable-validation-icons {
            padding-right: $input-height-inner;
            background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
        }
    }
}

.custom-select {
    &.is-warning {
        border-color: theme-color('warning');

        @if $enable-validation-icons {
            // padding-right: $custom-select-feedback-icon-padding-right;
            // background: $custom-select-background, none $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
        }

        &:focus {
            border-color: theme-color('warning');
            box-shadow: 0 0 0 $input-focus-width rgba(theme-color('warning'), .25);
        }

        ~ .warning-feedback,
        ~ .warning-tooltip {
            display: block;
        }
    }
}

.form-control-file {
    &.is-warning {
        ~ .warning-feedback,
        ~ .warning-tooltip {
            display: block;
        }
    }
}

.form-check-input {
    &.is-warning {
        ~ .form-check-label {
            color: theme-color('warning');
        }

        ~ .warning-feedback,
        ~ .warning-tooltip {
            display: block;
        }
    }
}

.custom-control-input.is-warning {
    ~ .custom-control-label {
        color: theme-color('warning');

        &::before {
            border-color: theme-color('warning');
        }
    }

    ~ .warning-feedback,
    ~ .warning-tooltip {
        display: block;
    }

    &:checked {
        ~ .custom-control-label::before {
            @include gradient-bg(lighten(theme-color('warning'), 10%));
            border-color: lighten(theme-color('warning'), 10%);
        }
    }

    &:focus {
        ~ .custom-control-label::before {
            box-shadow: 0 0 0 $input-focus-width rgba(theme-color('warning'), .25);
        }

        &:not(:checked) ~ .custom-control-label::before {
            border-color: theme-color('warning');
        }
    }
}

// custom file
.custom-file-input {
    &.is-warning {
        ~ .custom-file-label {
            border-color: theme-color('warning');
        }

        ~ .warning-feedback,
        ~ .warning-tooltip {
            display: block;
        }

        &:focus {
            ~ .custom-file-label {
                border-color: theme-color('warning');
                box-shadow: 0 0 0 $input-focus-width rgba(theme-color('warning'), .25);
            }
        }
    }
}

// custom switch color variations
.custom-switch {
    @each $name, $color in $theme-colors {
        @include custom-switch-variant($name, $color);
    }

    @each $name, $color in $colors {
        @include custom-switch-variant($name, $color);
    }
}

// custom range color variations
.custom-range {
    @each $name, $color in $theme-colors {
        @include custom-range-variant($name, $color);
    }

    @each $name, $color in $colors {
        @include custom-range-variant($name, $color);
    }
}