//
// Component: Cards
//

// Color variants
@each $name, $color in $theme-colors {
    @include cards-variant($name, $color);
}

@each $name, $color in $colors {
    @include cards-variant($name, $color);
}

.card {
    @include box-shadow($card-shadow);
    margin-bottom: map-get($spacers, 3);

    &.bg-dark {
        .card-header {
            border-color: $card-dark-border-color;
        }

        &,
        .card-body {
            color: $white;
        }
    }

    &.maximized-card {
        height: 100% !important;
        left: 0;
        max-height: 100% !important;
        max-width: 100% !important;
        position: fixed;
        top: 0;
        width: 100% !important;
        z-index: 9999;

        &.was-collapsed .card-body {
            display: block !important;
        }

        [data-widget='collapse'] {
            display: none;
        }

        .card-header,
        .card-footer {
            @include border-radius(0 !important);
        }
    }
    // collapsed mode
    &.collapsed-card {
        .card-body,
        .card-footer {
            display: none;
        }
    }

    .nav.flex-column {
        > li {
            border-bottom: 1px solid $card-border-color;
            margin: 0;

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
    // fixed height to 300px
    &.height-control {
        .card-body {
            max-height: 300px;
            overflow: auto;
        }
    }

    .border-right {
        border-right: 1px solid $card-border-color;
    }

    .border-left {
        border-left: 1px solid $card-border-color;
    }

    &.card-tabs {
        &:not(.card-outline) {
            & > .card-header {
                border-bottom: 0;

                .nav-item {
                    &:first-child .nav-link {
                        margin-left: -1px;
                    }
                }
            }
        }

        &.card-outline {
            .nav-item {
                border-bottom: 0;

                &:first-child .nav-link {
                    border-left: 0;
                    margin-left: 0;
                }
            }
        }

        .card-tools {
            margin: .3rem .5rem;
        }

        &:not(.expanding-card).collapsed-card {
            .card-header {
                border-bottom: 0;

                .nav-tabs {
                    border-bottom: 0;

                    .nav-item {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.expanding-card {
            .card-header {
                .nav-tabs {
                    .nav-item {
                        margin-bottom: -1px;
                    }
                }
            }
        }
    }

    &.card-outline-tabs {
        border-top: 0;

        .card-header {
            .nav-item {
                &:first-child .nav-link {
                    border-left: 0;
                    margin-left: 0;
                }
            }

            a {
                border-top: 3px solid transparent;

                &:hover {
                    border-top: 3px solid $nav-tabs-border-color;
                }

                &.active {
                    &:hover {
                        margin-top: 0;
                    }
                }
            }
        }

        .card-tools {
            margin: .5rem .5rem .3rem;
        }

        &:not(.expanding-card).collapsed-card .card-header {
            border-bottom: 0;

            .nav-tabs {
                border-bottom: 0;

                .nav-item {
                    margin-bottom: 0;
                }
            }
        }

        &.expanding-card {
            .card-header {
                .nav-tabs {
                    .nav-item {
                        margin-bottom: -1px;
                    }
                }
            }
        }
    }
}

// Maximized Card Body Scroll fix
html.maximized-card {
    overflow: hidden;
}

// Add clearfix to header, body and footer
.card-header,
.card-body,
.card-footer {
    @include clearfix;
}

// Box header
.card-header {
    background-color: transparent;
    border-bottom: 1px solid $card-border-color;
    padding: (($card-spacer-y / 2) * 2) $card-spacer-x;
    position: relative;

    @if $enable-rounded {
        @include border-top-radius($border-radius);
    }

    .collapsed-card & {
        border-bottom: 0;
    }

    > .card-tools {
        float: right;
        margin-right: -$card-spacer-x / 2;

        .input-group,
        .nav,
        .pagination {
            margin-bottom: -$card-spacer-y / 2.5;
            margin-top: -$card-spacer-y / 2.5;
        }

        [data-toggle='tooltip'] {
            position: relative;
        }
    }
}

.card-title {
    float: left;
    font-size: $card-title-font-size;
    font-weight: $card-title-font-weight;
    margin: 0;
}

.card-text {
    clear: both;
}

// Box Tools Buttons
.btn-tool {
    background: transparent;
    color: $gray-500;
    font-size: $font-size-sm;
    margin: -(($card-spacer-y / 2) * 2) 0;
    padding: .25rem .5rem;

    .btn-group.show &,
    &:hover {
        color: $gray-700;
    }

    .show &,
    &:focus {
        box-shadow: none !important;
    }
}

.text-sm {
    .card-title {
        font-size: $card-title-font-size-sm;
    }

    .nav-link {
        padding: $card-nav-link-padding-sm-y $card-nav-link-padding-sm-x;
    }
}

// Box Body
.card-body {
    // @include border-radius-sides(0, 0, $border-radius, $border-radius);
    // .no-header & {
    //   @include border-top-radius($border-radius);
    // }
    // Tables within the box body
    > .table {
        margin-bottom: 0;

        > thead > tr > th,
        > thead > tr > td {
            border-top-width: 0;
        }
    }
    // Calendar within the box body
    .fc {
        margin-top: 5px;
    }

    .full-width-chart {
        margin: -19px;
    }

    &.p-0 .full-width-chart {
        margin: -9px;
    }
}

.chart-legend {
    @include list-unstyled;
    margin: 10px 0;

    > li {
        @media (max-width: map-get($grid-breakpoints, sm)) {
            float: left;
            margin-right: 10px;
        }
    }
}

// Comment Box
.card-comments {
    background: $gray-100;

    .card-comment {
        @include clearfix;
        border-bottom: 1px solid $gray-200;
        padding: 8px 0;

        &:last-of-type {
            border-bottom: 0;
        }

        &:first-of-type {
            padding-top: 0;
        }

        img {
            height: $card-img-size;
            width: $card-img-size;
            float: left;
        }
    }

    .comment-text {
        color: lighten($gray-700, 20%);
        margin-left: 40px;
    }

    .username {
        color: $gray-700;
        display: block;
        font-weight: 600;
    }

    .text-muted {
        font-size: 12px;
        font-weight: 400;
    }
}

// Widgets
//-----------

// Widget: TODO LIST
.todo-list {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;
    // Todo list element
    > li {
        @include border-radius(2px);
        background: $gray-100;
        border-left: 2px solid $gray-200;
        color: $gray-700;
        margin-bottom: 2px;
        padding: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        > input[type='checkbox'] {
            margin: 0 10px 0 5px;
        }

        .text {
            display: inline-block;
            font-weight: 600;
            margin-left: 5px;
        }
        // Time labels
        .badge {
            font-size: .7rem;
            margin-left: 10px;
        }
        // Tools and options box
        .tools {
            color: theme-color('danger');
            display: none;
            float: right;
            // icons
            > .fa,
            > .fas,
            > .far,
            > .fab,
            > .glyphicon,
            > .ion {
                cursor: pointer;
                margin-right: 5px;
            }
        }

        &:hover .tools {
            display: inline-block;
        }

        &.done {
            color: darken($gray-500, 25%);

            .text {
                font-weight: 500;
                text-decoration: line-through;
            }

            .badge {
                background: $gray-500 !important;
            }
        }
    }
    // Color variants
    @each $name, $color in $theme-colors {
        .#{$name} {
            border-left-color: $color;
        }
    }

    @each $name, $color in $colors {
        .#{$name} {
            border-left-color: $color;
        }
    }

    .handle {
        cursor: move;
        display: inline-block;
        margin: 0 5px;
    }
}

// END TODO WIDGET

// Input in box
.card-input {
    max-width: 200px;
}

// Nav Tabs override
.card-default {
    .nav-item {
        &:first-child .nav-link {
            border-left: 0;
        }
    }
}