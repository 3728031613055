/**
  * bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
  *
  * @version v3.4 (MODDED)
  * @homepage https://bttstrp.github.io/bootstrap-switch
  * @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
  * @license MIT
  */

$bootstrap-switch-border-radius: $btn-border-radius;
$bootstrap-switch-handle-border-radius: .1rem;

.bootstrap-switch {
    border: $input-border-width solid $input-border-color;
    border-radius: $bootstrap-switch-border-radius;
    cursor: pointer;
    direction: ltr;
    display: inline-block;
    line-height: .5rem;
    overflow: hidden;
    position: relative;
    text-align: left;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    user-select: none;
    vertical-align: middle;
    z-index: 0;

    .bootstrap-switch-container {
        border-radius: $bootstrap-switch-border-radius;
        display: inline-block;
        top: 0;
        transform: translate3d(0, 0, 0);
    }

    &:focus-within {
        box-shadow: $input-btn-focus-box-shadow;
    }

    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
        box-sizing: border-box;
        cursor: pointer;
        display: table-cell;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2rem;
        padding: .25rem .5rem;
        vertical-align: middle;
    }

    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off {
        text-align: center;
        z-index: 1;

        &.bootstrap-switch-default {
            background: $gray-200;
            color: color-yiq($gray-200);
        }

        @each $name, $color in $theme-colors {
            &.bootstrap-switch-#{$name} {
                background: $color;
                color: color-yiq($color);
            }
        }

        @each $name, $color in $colors {
            &.bootstrap-switch-#{$name} {
                background: $color;
                color: color-yiq($color);
            }
        }
    }

    .bootstrap-switch-handle-on {
        border-bottom-left-radius: $bootstrap-switch-handle-border-radius;
        border-top-left-radius: $bootstrap-switch-handle-border-radius;
    }

    .bootstrap-switch-handle-off {
        border-bottom-right-radius: $bootstrap-switch-handle-border-radius;
        border-top-right-radius: $bootstrap-switch-handle-border-radius;
    }

    input[type='radio'],
    input[type='checkbox'] {
        filter: alpha(opacity=0);
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        z-index: -1;
    }

    &.bootstrap-switch-mini {
        .bootstrap-switch-handle-on,
        .bootstrap-switch-handle-off,
        .bootstrap-switch-label {
            font-size: .875rem;
            line-height: 1.5;
            padding: .1rem .3rem;
        }
    }

    &.bootstrap-switch-small {
        .bootstrap-switch-handle-on,
        .bootstrap-switch-handle-off,
        .bootstrap-switch-label {
            font-size: .875rem;
            line-height: 1.5;
            padding: .2rem .4rem;
        }
    }

    &.bootstrap-switch-large {
        .bootstrap-switch-handle-on,
        .bootstrap-switch-handle-off,
        .bootstrap-switch-label {
            font-size: 1.25rem;
            line-height: 1.3333333rem;
            padding: .3rem .5rem;
        }
    }

    &.bootstrap-switch-disabled,
    &.bootstrap-switch-readonly,
    &.bootstrap-switch-indeterminate {
        cursor: default;

        .bootstrap-switch-handle-on,
        .bootstrap-switch-handle-off,
        .bootstrap-switch-label {
            cursor: default;
            filter: alpha(opacity=50);
            opacity: .5;
        }
    }

    &.bootstrap-switch-animate .bootstrap-switch-container {
        transition: margin-left .5s;
    }

    &.bootstrap-switch-inverse {
        .bootstrap-switch-handle-on {
            border-radius: 0 $bootstrap-switch-handle-border-radius $bootstrap-switch-handle-border-radius 0;
        }

        .bootstrap-switch-handle-off {
            border-radius: $bootstrap-switch-handle-border-radius 0 0 $bootstrap-switch-handle-border-radius;
        }
    }
    // &.bootstrap-switch-focused {
    //   border-color: $input-btn-focus-color;
    //   box-shadow: $input-btn-focus-box-shadow;
    //   outline: 0;
    // }
    &.bootstrap-switch-on .bootstrap-switch-label,
    &.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
        border-bottom-right-radius: $bootstrap-switch-handle-border-radius;
        border-top-right-radius: $bootstrap-switch-handle-border-radius;
    }

    &.bootstrap-switch-off .bootstrap-switch-label,
    &.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
        border-bottom-left-radius: $bootstrap-switch-handle-border-radius;
        border-top-left-radius: $bootstrap-switch-handle-border-radius;
    }
}