//
// Component: Small Box
//

.small-box {
    @include border-radius($border-radius);
    @include box-shadow($card-shadow);
    display: block;
    margin-bottom: 20px;
    position: relative;
    // content wrapper
    > .inner {
        padding: 10px;
    }

    > .small-box-footer {
        background: rgba($black, 0.1);
        color: rgba($white, 0.8);
        display: block;
        padding: 3px 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        z-index: 10;

        &:hover {
            background: rgba($black, 0.15);
            color: $white;
        }
    }

    h3 {
        @include font-size(2.2rem);
        font-weight: bold;
        margin: 0 0 10px 0;
        padding: 0;
        white-space: nowrap;
    }

    @include media-breakpoint-up(lg) {
        .col-xl-2 &,
        .col-lg-2 &,
        .col-md-2 & {
            h3 {
                @include font-size(1.6rem);
            }
        }

        .col-xl-3 &,
        .col-lg-3 &,
        .col-md-3 & {
            h3 {
                @include font-size(1.6rem);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .col-xl-2 &,
        .col-lg-2 &,
        .col-md-2 & {
            h3 {
                @include font-size(2.2rem);
            }
        }

        .col-xl-3 &,
        .col-lg-3 &,
        .col-md-3 & {
            h3 {
                @include font-size(2.2rem);
            }
        }
    }

    p {
        font-size: 1rem;

        > small {
            color: $gray-100;
            display: block;
            font-size: 0.9rem;
            margin-top: 5px;
        }
    }

    h3,
    p {
        z-index: 5;
    }
    // the icon
    .icon {
        color: rgba($black, 0.15);
        z-index: 0;

        > i {
            font-size: 90px;
            position: absolute;
            right: 15px;
            top: 15px;
            transition: all $transition-speed linear;

            &.fa,
            &.fas,
            &.far,
            &.fab,
            &.glyphicon,
            &.ion {
                font-size: 70px;
                top: 20px;
            }
        }
    }
    // Small box hover state
    &:hover {
        text-decoration: none;
        // Animate icons on small box hover
        .icon > i {
            font-size: 95px;

            &.fa,
            &.fas,
            &.far,
            &.fab,
            &.glyphicon,
            &.ion {
                font-size: 75px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    // No need for icons on very small devices
    .small-box {
        text-align: center;

        .icon {
            display: none;
        }

        p {
            font-size: 12px;
        }
    }
}