//
// Pages: Profile
//

.profile-user-img {
    border: 3px solid $gray-500;
    margin: 0 auto;
    padding: 3px;
    width: 100px;
}

.profile-username {
    font-size: 21px;
    margin-top: 5px;
}

.post {
    border-bottom: 1px solid $gray-500;
    color: #666;
    margin-bottom: 15px;
    padding-bottom: 15px;

    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .user-block {
        margin-bottom: 15px;
        width: 100%;
    }

    .row {
        width: 100%;
    }
}