//
// Mixins: Miscellaneous
//

// ETC
@mixin translate($x, $y) {
    transform: translate($x, $y);
}

// Different radius each side
@mixin border-radius-sides($top-left, $top-right, $bottom-left, $bottom-right) {
    border-radius: $top-left $top-right $bottom-left $bottom-right;
}

@mixin calc($property, $expression) {
    #{$property}: calc(#{$expression});
}

@mixin rotate($value) {
    transform: rotate($value);
}

@mixin animation($animation) {
    animation: $animation;
}

// Gradient background
@mixin gradient($color: #F5F5F5, $start: #EEE, $stop: #FFF) {
    background: $color;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, $start), color-stop(1, $stop));
    background: -ms-linear-gradient(bottom, $start, $stop);
    background: -moz-linear-gradient(center bottom, $start 0%, $stop 100%);
    background: -o-linear-gradient($stop, $start);
}