//
// Component: Text
//

// text modification
.text-bold {
    &, &.table td, &.table th {
        font-weight: 700;
    }
}

.text-xs {
    font-size: $font-size-xs !important;
}

.text-sm {
    font-size: $font-size-sm !important;
}

.text-md {
    font-size: $font-size-base !important;
}

.text-lg {
    font-size: $font-size-lg !important;
}

.text-xl {
    font-size: $font-size-xl !important;
}

// text color variations
@each $name, $color in $colors {
    .text-#{$name} {
        color: #{$color} !important;
    }
}